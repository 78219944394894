import { getApiURL, getDeskproURL } from './Environment';
import { getStoredUserAuth } from './Storage';
import {TLD, User} from '../custom-types';
import {
  TotpLoginResponse,
  LoginRequest,
  LoginResponse,
  TotpLoginRequest,
  GenerateTotpResponse,
  UserResponse,
  StatusResponse,
  ResetPasswordCompleteRequest,
  GetDeskproTokenResponse,
  CreateDeskproTicketResponse,
  ListResponse,
  WhoisResponse,
  RegistryUnlockRequest,
  ListUsersQueryParams,
  AddAPIClientParams,
  AddAPIClientResponse,
  RegistrarLogoUploadRequest,
  RegistrarUpdateRequest,
  UpdateLimitedParams,
  StatsRankResponse,
  StatsGeneralResponse,
  StatsRegistrarResponse, ErrorResponse
} from '../custom-types/Request';
import {InvoiceData, InvoicePDFResponse, InvoicePaymentIDResponse} from "../custom-types/Invoice";
import {nullCheckRegistrarVals} from "./Helpers";
import {DomainListRequest, Domain} from "../custom-types/Domain";
import NewCertificate from '../custom-types/NewCertificate';
import APIClient from "../custom-types/APIClient";
import {
  RegistrarContact,  
  RegistrarInfoResponse, 
  RegistrarListingDetails
} from "../custom-types/Registrar";
import {DeskproNews} from "../custom-types/Deskpro";
import {ExportResponse} from "../custom-types/Export";
import {Err, Ok, Result} from "./Result";

export const parseJsonError = (res: Response): Promise<ErrorResponse> => {
  const errObj: ErrorResponse = {errors: [res.statusText]};

  return res.json().then((error) => {
    if (error && {}.propertyIsEnumerable.call(error, 'errors')) {
      errObj.errors = error.errors;
    }

    if (error && {}.propertyIsEnumerable.call(error, 'fields')) {
      errObj.fields = error.fields;
    }

    return Promise.reject(errObj);
  }).catch(() => {
    // Catch empty body / cannot be json parsed
    return Promise.reject(errObj);
  })
};

/**
 * API Request handler
 * @param url        - api endpoint
 * @param method     - http method
 * @param token      - User auth token
 * @param urlParams  - Url Query params
 * @param bodyParams - body parameters of request
 */
const apiRequest = async (
  url: string,
  method: string,
  token?: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  urlParams?: { },
  // eslint-disable-next-line @typescript-eslint/ban-types
  bodyParams?: { },
  responseType?: 'blob' | 'json',
  // eslint-disable-next-line
): Promise<any> => {
  const myHeaders = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

  if (token && token !== null) {
    myHeaders.set('Authorization', token);
  }

  const apiURL = getApiURL();
  let fullUrl = apiURL && !(url.startsWith('.') || url.startsWith('http')) ? `${apiURL}${url}` : url;

  if (urlParams && urlParams !== null) {
    const stringParams = new URLSearchParams(urlParams).toString();
    fullUrl = `${fullUrl}?${stringParams}`;
  }

  // nosemgrep     Nodejs SSRF in not an issue here (CWE-918)
  const req = fetch(
    fullUrl,
    {
      method,
      headers: myHeaders,
      body: bodyParams ? JSON.stringify(bodyParams) : undefined,
    },
  );

  return req.then((res) => {
    if (!res.ok) {
      return parseJsonError(res);
    }

    switch (responseType) {
      case "blob": {
        return res.blob();
      }
      case "json": {
        return res.json();
      }
      default: {
        return res.json();
      }
    }
  }).then((resData) => new Ok(resData))
    .catch((reason) => new Err(reason))
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const applyForRegistrar = (params: {}): Promise<Result<StatusResponse, ErrorResponse>> => {
  return apiRequest("/v2/registrars/application_form",
    "POST",
    undefined,
    undefined,
    params);
};

export const getUsersForRegistrar = (params?: ListUsersQueryParams): Promise<Result<ListResponse<User>, ErrorResponse>> => {
  const userAuth = getStoredUserAuth()
  return apiRequest("/v2/users",
                    "GET",
                    userAuth.token,
                    Object.assign({'registrar_id': userAuth.registrarId}, params));
};

export const getWebUsersForRegistrar = (): Promise<Result<ListResponse<User>, ErrorResponse>> => {
  return getUsersForRegistrar({ "role": "regweb_registrar"})
};

export const invalidateTotpForUser = (username: string): Promise<Result<StatusResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/users/${username}/totp_invalidate`,
                    "POST",
                    userAuth.token,
                    undefined,
                    undefined)
};

export const updateLimited = (username: string, params?: UpdateLimitedParams): Promise<Result<User, ErrorResponse>> => {
  const userAuth = getStoredUserAuth()
  return apiRequest(`/v2/users/${username}/limited`, "PATCH", userAuth.token, undefined, params);
};

export const getEPPUsersForRegistrar = async (): Promise<Result<ListResponse<User>, ErrorResponse>> => {
  return getUsersForRegistrar({ "role": "epp_registrar" });
}

export const getWhois = (domain: string, tld: string): Promise<Result<WhoisResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth()
  return apiRequest(`/v2/whois/${tld}/${domain}`, 'GET', userAuth.token, undefined, undefined);
}

export const submitSupportForm = async (formData: FormData): Promise<CreateDeskproTicketResponse> => {
  const deskproApiKey = '1:BH9AJKQ4B3BY55TBX3H5DHRW7';
  const deskproUrl = `${getDeskproURL()}/api/tickets?API-KEY=${deskproApiKey}`;

  const req = fetch(
    deskproUrl,
    {
      method: 'POST',
      body: formData,
    },
  );

  return req.then((res) => {
    if (!res.ok) {
      return Promise.reject(res.json().then((error) => {
        // If the JSON includes an error_code element, then an error has occurred.
        // If error_code is “multiple”, then a list of errors may be found in the errors element.
        // Each entry in errors represents an error
        if (error.error_message && error.error_message.includes('multiple')) {
          return { error: error.errors }
        }

        if (error.error_message) {
          return { error: [error.error_message] };
        }

        return { error: [res.statusText] };
      }))
    }

    return res.json();
  });
}

/**
 * Depending on which param is provided export will start for either domains or invoice
 * @param tld - for which TLD to export domains
 * @param invoiceNumber - the invoice number to start export for
 */
export const startRegistrarExport = (tld?: TLD, invoiceNumber?: number): Promise<Result<ExportResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  const url = tld ? `/v2/registrar/domains/${tld}/export` : `/v2/registrar/invoice/${invoiceNumber}/items/export`;
  return apiRequest(url, 'POST', userAuth.token, undefined, undefined);
};

export const checkExportStatus = (id: number): Promise<Result<ExportResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/registrar/export/${id}`, 'GET', userAuth.token, undefined, undefined);
};

export const login = (reqData: LoginRequest): Promise<Result<LoginResponse, ErrorResponse>> => {
  return apiRequest('/v2/session/auth', 'POST', undefined, undefined, reqData);
};

// eslint-disable-next-line max-len
export const totpLogin = (token: string | undefined, reqData: TotpLoginRequest): Promise<Result<TotpLoginResponse, ErrorResponse>> => {
  return apiRequest('/v2/session/auth_totp', 'POST', token, undefined, reqData);
};

export const generateTotp = (username: string, token: string): Promise<Result<GenerateTotpResponse, ErrorResponse>> => {
  return apiRequest(`/v2/users/${username}/totp_generate`, 'POST', token, undefined, undefined);
};

export const resetPassword = (username: string): Promise<Result<StatusResponse, ErrorResponse>> => {
  return apiRequest(`/v2/users/${username}/reset_password`, 'POST', undefined, undefined, {});
}

// eslint-disable-next-line max-len
export const resetPasswordComplete = (username: string, data: ResetPasswordCompleteRequest): Promise<Result<StatusResponse, ErrorResponse>> => {
  return apiRequest(`/v2/users/${username}/reset_password_complete`, 'POST', undefined, undefined, data);
}

export const getCurrentUser = (token: string): Promise<Result<UserResponse, ErrorResponse>> => {
  return apiRequest('/v2/user', 'GET', token, undefined, undefined);
}

export const getDeskproToken = (token: string): Promise<Result<GetDeskproTokenResponse, ErrorResponse>> => {
  return apiRequest('/v2/user/deskpro_token', 'GET', token, undefined, undefined);
}

export const getDeskproNews = (includePrivateNews: boolean): Promise<Result<DeskproNews, ErrorResponse>> => {
  const token = includePrivateNews ? getStoredUserAuth().token : undefined;
  return apiRequest('/v2/deskpro/api/news', 'GET', token, undefined, undefined);
};

export const getAPIClientsForOwnRegistrar = (): Promise<Result<ListResponse<APIClient>, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/registrar/apiclients`, 'GET', userAuth.token, undefined, undefined);
}

export const addAPIClient = (params: AddAPIClientParams): Promise<Result<AddAPIClientResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/apiclients`, 'POST', userAuth.token, undefined, params);
}

export const deleteAPIClient = (clientId: string): Promise<Result<StatusResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/apiclients`, 'DELETE', userAuth.token, undefined, { client_id: clientId});
}

export const getGeneralStats = (tld: TLD): Promise<Result<StatsGeneralResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v1/statistics/${tld}/`, 'GET', userAuth.token, undefined, undefined);
};

export const getRegistrarStats = (tld: TLD): Promise<Result<StatsRegistrarResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v1/statistics/${tld}/registrar`, 'GET', userAuth.token, undefined, undefined);
};

export const getRegistrarCustomerStats = (tld: TLD): Promise<Result<StatsRegistrarResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v1/statistics/${tld}/registrar/customers`, 'GET', userAuth.token, undefined, undefined);
};

export const getRegistrarStatsRank = (tld: TLD): Promise<Result<StatsRankResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v1/statistics/${tld}/registrar/ranks/`, 'GET', userAuth.token, undefined, undefined);
}

// eslint-disable-next-line max-len
export const getInvoiceFile = (registrarId: string, invoiceNo: number): Promise<Result<InvoicePDFResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/invoices/pdf/${registrarId}/${invoiceNo}`, 'GET', userAuth.token, undefined, undefined);
}

export const getInvoices = (urlParams: object): Promise<Result<ListResponse<InvoiceData>, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest('/v2/registrar/invoices', 'GET', userAuth.token, urlParams, undefined);
}

export const getInvoicePaymentID = (invoiceNumber: number): Promise<Result<InvoicePaymentIDResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  const bodyParams = {
    checkout_url: `${window.location.origin}/invoices`
  };

  return apiRequest(`/v2/registrar/invoice/${invoiceNumber}/payment`, 'POST', userAuth.token, undefined, bodyParams);
}

// eslint-disable-next-line max-len
export const getDomainsList = (urlParams: DomainListRequest, tld: TLD): Promise<Result<ListResponse<Domain>, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/registrar/domains/${tld}`, 'GET', userAuth.token, urlParams, undefined);
}

export const registryLockDomain = (domain: string): Promise<Result<StatusResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/domains/${domain}/lock`, 'POST', userAuth.token, undefined, undefined);
}

// eslint-disable-next-line max-len
export const registryUnlockDomain = (domain: string, reqData: RegistryUnlockRequest): Promise<Result<StatusResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/domains/${domain}/unlock`, 'POST', userAuth.token, undefined, reqData);
}

export const uploadRegistrarLogo = (params: RegistrarLogoUploadRequest): Promise<Result<StatusResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/registrar/logo`, 'PUT', userAuth.token, undefined, params);
}

// eslint-disable-next-line max-len
export const updateRegistrarInfo = (params: RegistrarUpdateRequest): Promise<Result<RegistrarInfoResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();

  // HACK: force empty strings to be null or else update
  // will not work.
  params.contact_admin = nullCheckRegistrarVals(params.contact_admin) as RegistrarContact;
  params.contact_billing = nullCheckRegistrarVals(params.contact_billing) as RegistrarContact;
  params.contact_tech = nullCheckRegistrarVals(params.contact_tech) as RegistrarContact;
  params.listing_details = nullCheckRegistrarVals(params.listing_details) as RegistrarListingDetails;

  return apiRequest(`/v2/registrar`, 'PUT', userAuth.token, undefined, params);
}

export const getRegistrarInfo = (): Promise<Result<RegistrarInfoResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/registrar`, 'GET', userAuth.token, undefined, undefined);
}

export const resetEPPPassword = (username: string): Promise<Result<StatusResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/users/${username}/reset_password_epp`, 'POST', userAuth.token, undefined, undefined);
}

export const getUser = (username: string): Promise<Result<User, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/users/${username}`, 'GET', userAuth.token, undefined, undefined);
}

export const getUserCertificates = (username: string): Promise<Result<ListResponse<NewCertificate>, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/users/${username}/certificates`, 'GET', userAuth.token, undefined, undefined);
}

export const addUserCertificate = (username: string, pem: string): Promise<Result<NewCertificate, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/users/${username}/certificates`, 'POST', userAuth.token, undefined, {pem: pem});
}

export const deleteUserCertificate = (username: string, id: number): Promise<Result<StatusResponse, ErrorResponse>> => {
  const userAuth = getStoredUserAuth();
  return apiRequest(`/v2/users/${username}/certificates/${id}`, 'DELETE', userAuth.token, undefined, undefined);
}
