/**
 * According to api v1 spec
 */

export enum InvoiceState {
  Draft = 'draft',
  Sending = 'sending',
  Sent = 'sent',
  Paid = 'paid',
  CCPaid = 'cc_paid',
}

/**
 * API v2
 */

export type InvoiceData = {
  invoice_no?: number,
  state: InvoiceState,
  order_month: string,
  invoice_date?: string,
  due_date?: string,
  paid_amount: number,
  adjusted_amount: number,
  external_payment_id?: string
}

export type InvoicePaymentIDResponse = {
  invoice_no: string;
  adjusted_amount: number;
  external_payment_id: string;
}

export type InvoicePDFResponse = {
  url: string;
}